@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 24 9.8% 6%;
		--foreground: 0 0% 95%;
		--card: 24 9.8% 3%;
		--card-foreground: 0 0% 95%;
		--popover: 0 0% 9%;
		--popover-foreground: 0 0% 98%;
		--primary: 342 74% 45%;
		--primary-foreground: 355.7 100% 97.3%;
		--secondary: 240 3.7% 15.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 0 0% 15%;
		--muted-foreground: 240 5% 64.9%;
		--accent: 90 93% 72%;
		--accent-foreground: 24 9.8% 6%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 85.7% 97.3%;
		--border: 240 3.7% 15.9%;
		--input: 240 3.7% 15.9%;
		--ring: 346.8 77.2% 49.8%;
		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	html {
		scroll-behavior: smooth;
	}
	body {
		@apply bg-background text-foreground font-sans;
	}
}
